<template>
    <v-container class="userLayout">
        <v-layout column>
            <v-form ref="form" v-model="valid" lazy-validation>
                <v-card v-if="userInfo">
                    <v-card-title class="text-h5">
                        <router-link to="/admin/users" class="mr-5"> <v-icon>fas fa-angle-left</v-icon></router-link> Add New
                    </v-card-title>
                    <v-card-text>
                        <div class="mb-4">
                            <v-avatar
                            color="primary"
                            size="56"
                            ><span class="white--text text-h5">{{getInitials(userInfo.name)}}</span></v-avatar>
                        </div>
                        <v-text-field
                            v-model="userInfo.name"
                            :rules="nameRules"
                            label="Name"></v-text-field>
                        <v-text-field
                            v-model="userInfo.email"
                            :rules="emailRules"
                            label="Email Address"></v-text-field>
                        <v-select
                                v-model="userInfo.status"
                                :items="[true,false]"
                                label="Enabled"
                                data-vv-name="Enabled"
                                required
                            >
                                <template v-slot:item="{ item, attrs, on }">
                                    <v-list-item v-bind="attrs" v-on="on">
                                        <v-list-item-title
                                        :id="attrs['aria-labelledby']"
                                        v-text="item"
                                        class="text-dark"
                                        >
                                        </v-list-item-title>
                                    </v-list-item>
                                </template>
                        </v-select>
                        <v-select
                            v-model="userInfo.role"
                            :items="['user','admin']"
                            label="Role"
                            data-vv-name="Role"
                            required
                        >
                            <template v-slot:item="{ item, attrs, on }">
                                <v-list-item v-bind="attrs" v-on="on">
                                    <v-list-item-title
                                    :id="attrs['aria-labelledby']"
                                    v-text="item"
                                    class="text-dark"
                                    >
                                    </v-list-item-title>
                                </v-list-item>
                            </template>
                        </v-select>
                        <v-text-field
                            v-model="newPass"
                            :rules="passwordRules"
                            :append-icon="showPass2 ? 'mdi-eye' : 'mdi-eye-off'"
                            @click:append="showPass2 = !showPass2"
                            :type="showPass2 ? 'text' : 'password'"
                            label="New Password"></v-text-field>
                        <div>
                            <p style="margin-bottom:5px">Password policy</p>
                            <ul>
                                <li :class="r1?'':'text-red'">At least one upper case English letter</li>
                                <li :class="r2?'':'text-red'">At least one lower case English letter</li>
                                <li :class="r3?'':'text-red'">At least one digit</li>
                                <li :class="r4?'':'text-red'">At least one special character</li>
                                <li :class="r5?'':'text-red'">Minimum eight in length</li>
                            </ul>
                        </div>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn color="primary" :loading="loading" @click.native="update">
                            <v-icon left dark>fas fa-check</v-icon>
                            Save Changes
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-layout>
        <v-snackbar v-model="error" :multi-line="true" color="red accent-2">
            {{ errorTxt }}
            <template v-slot:action="{ attrs }">
                <v-btn
                color="white"
                text
                v-bind="attrs"
                @click="error = false"
                >
                Close
                </v-btn>
            </template>
        </v-snackbar>
        <v-snackbar v-model="success" :multi-line="true" color="green">
            Account registered successfully
            <template v-slot:action="{ attrs }">
                <v-btn
                color="white"
                text
                v-bind="attrs"
                @click="success = false"
                >
                Close
                </v-btn>
            </template>
        </v-snackbar>
    </v-container>
</template>

<script>
export default {
    watch:{
        newPass:function(){
            if(!this.newPass){
                this.r1=true;
                this.r2=true;
                this.r3=true;
                this.r4=true;
                this.r5=true;
            }else{
                this.r1 = /^(?=.*?[A-Z])/.test(this.newPass)
                this.r2 = /^(?=.*?[a-z])/.test(this.newPass)
                this.r3 = /^(?=.*?[0-9])/.test(this.newPass)
                this.r4 = /^(?=.*?[#?!@$%^&*-])/.test(this.newPass)
                this.r5 = /.{8,}$/.test(this.newPass)
            }
        }
    },
    data(){
        return{
            r1:true,
            r2:true,
            r3:true,
            r4:true,
            r5:true,
            success:false,
            error:false,
            errorTxt:"",
            userInfo: {
                role:'user',
                status:true,
                name:"",
                email:"",
                password:"",
            },
            newPass:"",
            showPass1:false,
            showPass2:false,
            loading:false,
            valid: true,
            oldpassRules: [
                v => !!v || 'Old Password is required',
            ],
            nameRules: [
                v => !!v || 'Name is required',
            ],
            emailRules: [
                v => !!v || 'E-mail is required',
                v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
            ],
            passwordRules: [
                v => /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(v) || 'The password does not meet the password policy',
                //v => /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(v) || 'E-mail must be valid',
            ]
        }
    },
    async mounted(){
    },
    methods:{
        sendToUsers(){
            this.$router.push('/admin/users')
        },
        getInitials(name){
            if(name) {
                let array = name.split(" ");
                if(array.length == 1){
                    return array[0].charAt(0).toUpperCase();
                }
                else{
                    return array[0].charAt(0).toUpperCase() + array[ array.length -1 ].charAt(0).toUpperCase();
                }
            }
            return "NaN";
        },
        update(){
            this.loading = true;
            this.error = false;
            this.success = false;
            if(this.$refs.form.validate()){
                let sendData = {
                    name:this.userInfo.name,
                    email:this.userInfo.email,
                    role:this.userInfo.role,
                    status:this.userInfo.status,
                    password: this.newPass
                }
                console.log(sendData)
                this.$http.post(`${process.env.VUE_APP_API_ROOT}/users/new`,sendData)
                .then(this.handleRes)
                .catch(this.handleErr)
            }
            else{
                this.loading = false;
                this.error = true;
                this.errorTxt = "Please check all the fields"
            }
        },
        async handleRes(r){
            this.loading = false;
            this.success = true;
            this.newPass = ""
            this.$refs.form.resetValidation()
            this.$router.push(`/admin/users/edit/${r.data.data._id}?created=true`)
        },
        handleErr(e){
            this.loading = false;
            this.error = true;
            if(e.response.data.message.includes('E11000') && e.response.data.message.includes('email'))
                this.errorTxt = "Email address already exists"
            else this.errorTxt = e.response.data.message
        }
    }
}
</script>

<style>
.userLayout{
    max-width: 500px;
}
.text-red{
    color:red;
}
</style>